import { MatDialog, MatDialogRef, MatDialogConfig } from '@angular/material/dialog';
import { ConfirmDialogComponent, ConfirmDialogOptions } from '../components/confirm-dialog/confirm-dialog.component';
import { AlertDialogComponent, AlertDialogOptions } from '../components/alert-dialog/alert-dialog.component';

export class Utils {
    public static confirm(dialog: MatDialog, config: MatDialogConfig<ConfirmDialogOptions>): MatDialogRef<ConfirmDialogComponent> {
        const dialogRef = dialog.open(ConfirmDialogComponent, {
            data: {
                title: config.data.title ?? null,
                message: config.data.message,
                showInput: config.data.showInput,
                confirmButtonText: config.data.confirmButtonText ?? "Yes, do it",
                cancelButtonText: config.data.cancelButtonText ?? "Cancel",
                onCancel: config.data.onCancel ?? void 0,
                inputValidator: config.data.inputValidator ?? void 0,
                inputLabel: config.data.inputLabel ?? void 0,
            },
            width: config.width ? config.width : '350px',
            disableClose: config.disableClose != null ? config.disableClose : false
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result && config.data?.callback) {
                config.data.callback(result);
            }
        });
        return dialogRef;
    };

    public static alert(dialog: MatDialog, config: MatDialogConfig<AlertDialogOptions>): MatDialogRef<AlertDialogComponent> {
        const dialogRef = dialog.open(AlertDialogComponent, {
            data: {
                title: config.data.title ?? null,
                message: config.data.message,
                acceptButtonText: config.data.acceptButtonText ?? "OK",
                showAcceptButton: config.data.showAcceptButton != null ? config.data.showAcceptButton : true,
                type: config.data.type ?? null,
                actions: config.data.actions ?? null,
            },
            width: config.width ? config.width : '350px',
            disableClose: config.disableClose != null ? config.disableClose : false
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result === 'OK' && config.data?.callback) {
                config.data.callback();
            }
        });
        return dialogRef;
    };

    public static secondsToHHmmss(seconds: number): string {
        let minutes: number = Math.floor(seconds / 60);
        seconds = seconds % 60;
        let hours: number = Math.floor(minutes / 60);
        minutes = minutes % 60;
        return (hours.toString().length < 2 ? hours.toString().leftPad(2, '0') : hours.toString())
            + ":" + minutes.toString().leftPad(2, '0') + ":" + seconds.toString().leftPad(2, '0');
    }

    public static numberFormat(num: number, decimals: number, decimalPoint?: string, thousandsSeparator?: string): string {
        let n = !isFinite(+num) ? 0 : +num;
        let prec = !isFinite(+decimals) ? 0 : Math.abs(decimals);
        let sep = (typeof thousandsSeparator === 'undefined') ? ',' : thousandsSeparator;
        let dec = (typeof decimalPoint === 'undefined') ? '.' : decimalPoint;
        let toFixedFix = function (n: number, prec: number) {
            var k = Math.pow(10, prec);
            return Math.round(n * k) / k;
        },
            s = (prec ? toFixedFix(n, prec) : Math.round(n)).toString().split('.');
        if (s[0].length > 3) {
            s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
        }
        if ((s[1] || '').length < prec) {
            s[1] = s[1] || '';
            s[1] += new Array(prec - s[1].length + 1).join('0');
        }
        return s.join(dec);
    }

    public static getDeviceType(): string {
        const ua = navigator.userAgent;
        if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
            return "tablet";
        }
        if (
            /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
                ua
            )
        ) {
            return "mobile";
        }
        return "desktop";
    }
    public static nl2br(str: string, isXhtml?: boolean) {
        if (typeof str === 'undefined' || str === null) {
            return '';
        }
        const breakTag = (isXhtml || typeof isXhtml === 'undefined') ? '<br />' : '<br>';
        return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
    }
}