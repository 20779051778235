import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from '@angular/router';
import { CommerceService } from '../../services/commerce.service';
import { CashbackService } from '../../services/cashback.service';
import { UserService } from '../../services/user.service';
import { GoogleAnalyticsService } from '../../services/google-analytics.service';
import { WindowService } from '../../services/window.service';
import { ImageCropperComponent } from '../../components/image-cropper/image-cropper.component';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { Utils } from '../../utils/index';
import { faArrowLeft, faQuestionCircle, faCamera } from '@fortawesome/free-solid-svg-icons';
import { Title } from '@angular/platform-browser';
import { IFile } from './../../models/file';
import { Commerce } from './../../models/commerce';
import { HttpErrorResponse } from '@angular/common/http';
import { MatomoService } from '../../services/matomo.service';

@Component({
    selector: 'app-redeem',
    templateUrl: './redeem.component.html',
    styleUrls: ['./redeem.component.scss']
})
export class RedeemComponent implements OnInit {
    @ViewChild('cropper', { static: false }) cropper: ImageCropperComponent;;
    @ViewChild('copperContainer', { static: false }) fsRef: ElementRef;
    public faArrowLeft = faArrowLeft;
    public faQuestionCircle = faQuestionCircle;
    public cameraIcon = faCamera;
    public form: UntypedFormGroup;
    public commerces: Commerce[];
    public loadingCommerces: boolean = false;
    public cropperActive: boolean = false;
    public receipt: IFile;
    public camHeight: number;
    public paymentTypes = [
        { slug: 'cash', name: "Efectivo" },
        { slug: 'credit_card', name: "Tarjeta de crédito" },
        { slug: 'debit_card', name: "Tarjeta de débito" },
    ];
    public savingText: string = "Sending...";
    public okText: string = "OK";
    public offersText = {
        'on': '',
        'Total bill': '',
        'Some products': ''
    };
    public receiptError: string = null;

    constructor(
        private fb: UntypedFormBuilder,
        private _snackBar: MatSnackBar,
        public dialog: MatDialog,
        public translate: TranslateService,
        private router: Router,
        private location: Location,
        private activatedRoute: ActivatedRoute,
        private commerceService: CommerceService,
        private cashbackService: CashbackService,
        private userService: UserService,
        private titleService: Title,
        public googleAnalyticsService: GoogleAnalyticsService,
        public windowService: WindowService,
        private matomoService: MatomoService,
    ) {
        this.translate.get([this.savingText], {}).subscribe((trans) => {
            this.savingText = trans[this.savingText];
        });
        this.translate.get([this.okText], {}).subscribe((trans) => {
            this.okText = trans[this.okText];
        });
        this.translate.get(Object.keys(this.offersText), {}).subscribe((trans) => {
            this.offersText['on'] = trans['on'];
            this.offersText['Total bill'] = trans['Total bill'];
            this.offersText['Some products'] = trans['Some products'];
        });
        this.camHeight = this.windowService.innerHeight - 100;
    }

    ngOnInit() {
        this.getCommerces();
        this.form = this.fb.group({
            commerce_id: ['', Validators.required],
            purchase_payment_type: ['', Validators.required],
        });
        this.activatedRoute.queryParamMap.subscribe(queryParams => {
            if (queryParams.get("commerce")) {
                let id = queryParams.get("commerce");
                this.form.get('commerce_id').setValue(id);
            }
        });
        // Agregar por default el tipo de pago
        this.form.get('purchase_payment_type').setValue(this.paymentTypes[0].slug);
    }

    private getCommerces(): void {
        this.loadingCommerces = true;
        this.commerceService.getCommerces({ "no-paginate": 1, is_active: 1, has_active_offers: 1, include: 'logo' }).then(result => {
            this.loadingCommerces = false;
            this.commerces = result;
            for (let i = 0; i < result.length; i++) {
                if (result[i].uuid === this.activatedRoute.snapshot.queryParams['commerce']) {
                    this.setPageTitle(result[i]);
                }
            }
        }).catch(error => { });
    }

    public scrollToCropper() {
        const elem = this.fsRef.nativeElement;
        setTimeout(() => window.scrollTo(0, this.findPos(elem) - 56), 500);
    }

    private findPos(obj: any) {
        var curtop = 0;
        if (obj.offsetParent) {
            do {
                curtop += obj.offsetTop;
            } while (obj = obj.offsetParent);
        }
        return curtop;
    }

    public readFile(e: any) {
        let ref = null;
        if (e.target.files && e.target.files[0]) {
            this.googleAnalyticsService.eventEmitter("take_photo", "Cashbacks");
            const reader = new FileReader();
            reader.onload = (e: any) => {
                this.cropper.image.nativeElement.src = e.target.result;
                this.cropper.initCropper();
                this.cropperActive = true;
                ref.close();
                this.scrollToCropper();
            };
            ref = Utils.alert(this.dialog, {
                data: {
                    message: 'Cargando imagen...',
                    showAcceptButton: false,
                    type: 'loading',
                },
                disableClose: true,
            });
            reader.readAsDataURL(e.target.files[0]);
            this.cropper.data.name = e.target.files[0].name;
        }
    }

    public onImageCropped(data: IFile) {
        this.googleAnalyticsService.eventEmitter("cut_photo", "Cashbacks");
        this.receipt = data;
        this.cropperActive = false;
        this.cropper.hidePreview = false;
        this.receiptError = null;
    }

    public onSubmit(): void {
        this.receiptError = null;
        if (!this.receipt || (this.receipt && this.receipt.name === null)) {
            this.receiptError = "El campo recibo es requerido.";
        }
        if (!this.form.valid) {
            return;
        }
        let ref = Utils.alert(this.dialog, {
            data: {
                message: this.savingText + '...',
                showAcceptButton: false,
                type: 'loading',
            },
            disableClose: true,
        });
        let data = this.form.value;
        if (this.receipt && this.receipt.name !== null && this.receipt.contents) {
            data.receipt = this.receipt;
        }
        this.cashbackService.store(data).then((response) => {
            this.googleAnalyticsService.eventEmitter("request_cashback", "Cashbacks");
            this.matomoService.trackEvent("Cashbacks", "request_cashback");
            this.userService.getMyBalance().catch(error => { });
            Utils.alert(this.dialog, { data: {
                message: response.message,
                type: 'success',
                showAcceptButton: false,
                actions: {
                    "Ver status": () => {
                        this.googleAnalyticsService.eventEmitter("view_status", "Profile");
                        this.matomoService.trackEvent("Profile", "view_status");
                        this.router.navigate(['profile']);
                    },
                    "Cerrar": () => {
                        this.dialog.closeAll();
                    },
                }
            }});
            this.router.navigate(['home'], { replaceUrl: true });
        }).catch((response: HttpErrorResponse) => {
            if (response.status === 422) {
                this._snackBar.open(response.error.message, this.okText, {
                    duration: 8000
                });
                if (response.error.errors) {
                    Object.keys(response.error.errors).forEach(key => {
                        if (key === 'receipt') {
                            this.receiptError = response.error.errors[key][0];
                            return;
                        }
                        const formControl = this.form.get(key);
                        if (formControl) {
                            formControl.setErrors({
                                serverError: response.error.errors[key][0]
                            });
                        }
                    });
                }
            }
        }).then(() => {
            ref.close();
        });
    }

    public goBack() {
        this.googleAnalyticsService.eventEmitter("back_from_redeem", "Cashbacks");
        let state = this.location.getState();
        if (state.hasOwnProperty('navigationId')) {
            if (state['navigationId'] == 1) {
                this.router.navigate(['home'], { replaceUrl: true });
                return;
            }
        }
        this.location.back();
    }

    public setPageTitle(commerce: Commerce) {
        this.titleService.setTitle('Redimir - ' + commerce.name + ' | Moneo');
    }

    public getCashbackPercent(cbPercent: string, cbType: string) {
        let arrayCbPercent = cbPercent.split('|');
        cbPercent = `${arrayCbPercent[0]} % ${this.offersText['on']}`;
        cbPercent += ' ' + (cbType == 'total' ? `${this.offersText['Total bill']}` : `${this.offersText['Some products']}`);
        if (arrayCbPercent.length > 1) {
            cbPercent += ' + ' + this.getCashbackPercent(arrayCbPercent[1], 'total');
        }
        return cbPercent;
    }
}
